<template>
      <div class="px-1">
        <div class="row">
          <div class="col-12 d-flex align-center justify-space-between">
            <h2>Redes sociales</h2>
            <v-btn
              color="secondary"
              class="text--primary text-none elevation-0"
              @click="openSocialMediaModal()"
              dark
            >
              Nuevo
            </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <data-table
              ref="socialMedia"
              :url="url"
              :params="params"
              :headers="headers"
              :withoutPagination="true"
              :mobile="true"
            >
              <template v-slot:item.url="{ item }">
                <a v-if="item" :href="item.url" target="_blank" v-text="item.url"></a>
              </template>
              <template v-slot:item.arrows="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="!item.last"
                      large
                      color="black"
                      class="ml-5"
                      center
                      v-bind="attrs"
                      v-on="on"
                      @click="onChangeOrderSocialMedia(item, 0)"
                    >
                      fa fa-angle-down
                    </v-icon>
                    <v-icon large class="ml-10 pl-1" v-else></v-icon>
                  </template>
                  <span>Subir Posición</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="!item.first"
                      large
                      color="black"
                      class="ml-5"
                      center
                      v-bind="attrs"
                      v-on="on"
                      @click="onChangeOrderSocialMedia(item, 1)"
                    >
                      fa fa-angle-up
                    </v-icon>
                    <v-icon large class="ml-5" v-else></v-icon>
                  </template>
                  <span>Bajar Posición</span>
                </v-tooltip>
              </template>
              <template v-slot:item.isActive="{ item }">
                <v-switch
                  v-model="item.isActive"
                  dense
                  hide-details
                  class="ma-0"
                  @click="onChangeStatusSocialMedia(item)"
                ></v-switch>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      class="ma-2 elevation-0"
                      v-bind="attrs"
                      v-on="on"
                      @click="openSocialMediaModal(item)"
                      small
                      color="primary"
                    >
                      <v-icon center dark small>
                        fa fa-edit
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      class="ma-2 elevation-0"
                      v-bind="attrs"
                      v-on="on"
                      @click="onDeleteSocialMedia(item)"
                      small
                      color="primary"
                    >
                      <v-icon center dark small>
                        fa fa-trash
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar Red Social</span>
                </v-tooltip>
              </template>
            </data-table>
          </div>
        </div>
        <social-media-modal
          :fairId="params.fairId"
          ref="socialMediaModal"
          @onClose="onClose"
        />
        <confirm-dialog
          title="Confirmación"
          :description="
            `¿Deseas ${
              Boolean(selectedSocialMedia.isActive) ? 'activar' : 'desactivar'
            } la red social?`
          "
          @onClose="closeChangeStatusSocialMediaModal()"
          @onConfirm="changeStatusSocialMedia()"
          :is-active="isConfirmChangeStatusSocialMedia"
        />
        <confirm-dialog
          title="Confirmación"
          :description="
            `¿Deseas eliminar la red social?`
          "
          @onClose="closeDeleteSocialMediaModal()"
          @onConfirm="deleteSocialMedia()"
          :is-active="isConfirmDeleteSocialMedia"
        />
      </div>
</template>

<script>
import { SOCIAL_MEDIA_URL } from '@/constants/ServicesMobileConstants'
import headers from '@/views/private/general-settings/data/HeadersSocialMedia'
import DataTable from '@/components/data-table/DataTable'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import notificationMixin from '@/mixins/notificationMixin'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog'
import SocialMediaModal from '@/views/private/general-settings/components/SocialMediaModal.vue'

export default {
  name: 'SocialMedia',
  components: {
    DataTable,
    ConfirmDialog,
    SocialMediaModal
  },
  props: {
    fair: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      headers,
      params: {
        fairId: +(this.$route.params.fairId)
      },
      selectedSocialMedia: {},
      isConfirmDeleteSocialMedia: false,
      isConfirmChangeStatusSocialMedia: false,
      url: SOCIAL_MEDIA_URL
    }
  },
  methods: {
    openSocialMediaModal (item) {
      this.$refs.socialMediaModal.open(item)
    },
    onChangeOrderSocialMedia (item, toMove) {
      this.selectedSocialMedia = item
      this.selectedSocialMedia.toMove = toMove
      this.changeOrderSocialMedia()
    },
    onChangeStatusSocialMedia (item) {
      this.selectedSocialMedia = item
      this.isConfirmChangeStatusSocialMedia = false
      this.changeStatusSocialMedia()
    },
    onDeleteSocialMedia (item) {
      this.selectedSocialMedia = item
      this.isConfirmDeleteSocialMedia = true
    },
    onClose () {
      this.$refs.socialMedia.getDataFromApi()
    },
    closeDeleteSocialMediaModal () {
      this.isConfirmDeleteSocialMedia = false
    },
    closeChangeStatusInterestPlaceModal () {
      this.isConfirmChangeStatusSocialMedia = false
      this.selectedSocialMedia.isActive = !this.selectedSocialMedia
        .isActive
    },
    changeOrderSocialMedia () {
      this.getMobile(
        this.url +
        '/' +
        this.selectedSocialMedia.id +
        '/change-order/' +
        this.selectedSocialMedia.toMove
      ).then(res => {
        this.showSuccess(res.data.success)
        this.$refs.socialMedia.getDataFromApi()
      })
    },
    changeStatusSocialMedia () {
      this.putMobile(
        this.url + '/' + this.selectedSocialMedia.id + '/change-status/',
        this.selectedSocialMedia
      ).then(res => {
        this.showSuccess(res.data.success)
        this.$refs.socialMedia.getDataFromApi()
      })
    },
    deleteSocialMedia () {
      this.isConfirmDeleteSocialMedia = false
      this.deleteMobile(
        this.url,
        this.selectedSocialMedia.id
      ).then(res => {
        this.showSuccess(res.data.success)
        this.$refs.socialMedia.getDataFromApi()
      })
    }
  },
  mixins: [crudServiceMixin, crudServiceMobileMixin, notificationMixin]
}
</script>

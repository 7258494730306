<template>
  <div class="row">
    <v-dialog v-model="isActive" max-width="1000px" persistent scrollable>
      <v-card>
        <v-card-title class="mb-2">
          <span class="headline">
            {{ model.id ? "Edición" : "Creación" }} de Red Social
          </span>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="form">
            <div class="row">
              <div class="col-12 py-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="Link Red Social"
                  vid="url"
                  rules="required|max:255"
                >
                  <v-text-field
                    v-model="model.url"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Link Red Social"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 d-flex flex-column mb-4">
                <label class="font-weight-bold black--text">Imagen icono de la red social</label>
                <label class="black--text">Debes cargar un archivo de tipo: jpeg, jpg, png o gif</label>
              </div>
              <div class="col-12 col-md-6 py-0">
                <file-chooser
                  ref="fileChooser"
                  @input="onLoadIcon"
                  :options="{
                height: '190px',
                description: 'Haz clic aquí para cargar el icono de la red social',
                regex: /\.(jpg|jpeg|png|gif)$/,
                error: 'Solo se permiten imagenes',
                fileSize: 2,
              }">
                </file-chooser>
              </div>
            </div>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveSocialMedia">
            Aceptar
          </v-btn>
          <v-btn color="red" text @click="close">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import FileChooser from '@/components/file-chooser/FileChooser'
import { SOCIAL_MEDIA_URL } from '@/constants/ServicesMobileConstants'

export default {
  name: 'SocialMediaModal',
  components: {
    FileChooser
  },
  props: {
    order: {
      type: Number,
      default: null
    },
    fairId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      isActive: false,
      model: {
        id: null,
        name: null,
        url: null,
        order: this.order,
        socialMediaIconUrl: null,
        fairId: null
      }
    }
  },
  methods: {
    close () {
      this.isActive = false
      this.clean()
    },
    clean () {
      this.$refs.form.reset()
      this.model = {
        id: null,
        url: null,
        socialMediaIconUrl: null,
        order: null
      }
    },
    open (socialMedia) {
      this.isActive = true
      if (socialMedia) {
        this.$nextTick(() => {
          this.model = socialMedia
          this.$refs.fileChooser.setImage(socialMedia.socialMediaIconUrl, true)
        })
      }
    },
    saveSocialMedia () {
      if (!this.model.socialMediaIcon) {
        this.$refs.fileChooser.setError('El icono de la red social es requerido')
      }

      this.$refs.form.validate().then((result) => {
        const formData = new FormData()
        if (this.model.id) {
          formData.append('id', this.model.id)
        }
        if (this.model.socialMediaIcon) {
          formData.append('socialMediaIcon', this.model.socialMediaIcon)
        }

        formData.append('url', this.model.url)
        formData.append('fairId', this.fairId)
        if (result) {
          this.postMobile(SOCIAL_MEDIA_URL, formData)
            .then((res) => {
              this.close()
              this.showSuccess(res.data.success)
              this.$emit('onClose')
            })
        }
      })
    },
    onLoadIcon (file) {
      this.model.socialMediaIcon = file
    }
  },
  mixins: [
    crudServiceMobileMixin,
    notificationMixin,
    loaderMixin
  ]
}
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="isActive" max-width="800px" persistent scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">
            Títulos secciones
          </span>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="form">
            <v-container>
              <div class="col-12 mb-0 pb-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Título Interno"
                  vid="internalName"
                  rules="required|max:255"
                >
                  <v-text-field
                    v-model="model.internalName"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Título Interno"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 mb-0 pb-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Título Externo"
                  vid="externalName"
                  rules="required|max:255"
                >
                  <v-text-field
                    v-model="model.externalName"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Título Externo"
                    required
                  />
                </validation-provider>
              </div>
            </v-container
          ></validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save">
            Aceptar
          </v-btn>
          <v-btn color="red" text @click="close">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    ></v-row
  >
</template>

<script>
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import { APP_MODULES_URL } from '@/constants/ServicesMobileConstants'

export default {
  name: 'SectionTitleModal',
  data () {
    return {
      model: {
        internalName: null,
        externalName: null
      },
      isActive: false
    }
  },
  methods: {
    save () {
      this.$refs.form.validate().then((result) => {
        if (result) {
          if (this.model.id) {
            this.putMobile(APP_MODULES_URL + '/' + this.model.id + '/change-section-names', this.model)
              .then((res) => {
                this.showSuccess()
                this.$emit('set-titles', res.data.appModule)
                this.close()
              })
              .catch(error => {
                console.info(error)
              })
          }
        }
      })
    },
    open (data) {
      this.model.internalName = data.internalName
      this.model.externalName = data.externalName
      this.model.id = data.id
      this.isActive = true
    },
    close () {
      this.clean()
      this.isActive = false
    },
    clean () {
      this.model.internalName = null
      this.model.externalName = null
    }
  },
  mixins: [notificationMixin, crudServiceMobileMixin]
}
</script>

const headersSocialMedia = [
  {
    text: 'Orden',
    value: 'order',
    sortable: false
  },
  {
    text: 'Url',
    value: 'url',
    sortable: false
  },
  {
    text: '',
    value: 'arrows',
    sortable: false
  },
  {
    text: '',
    value: 'isActive',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false
  }
]
export default headersSocialMedia
